<template>
  <div>
    <div class="flex flex-row items-center w-1/3 py-2 space-x-2">
      <span>Username</span>
      <a-input placeholder="username" v-model="username" />
      <span>{{$t('type')}}</span>
      <a-select
        default-value=""
        style="width: 100%"
        @change="handleMemberTypeChange"
      >
        <a-select-option value="">
          {{$t('all')}}
        </a-select-option>
        <a-select-option value="agent">
          {{$t('agent')}}
        </a-select-option>
        <a-select-option value="user">
          {{$t('member')}}
        </a-select-option>
      </a-select>

      <a-button type="primary" @click="searchName">
        {{$t('search')}}
      </a-button>
    </div>
    <a-table :columns="columns" :data-source="myDownline" rowKey="_id" size="small" :scroll="{ x: 1000 }" :rowClassName="(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'">
      <a v-if="record.type === 'agent'" slot="username" slot-scope="text, record" @click="() => member(record._id)">{{ text }}</a>
      <a v-else slot="username" slot-scope="text">{{ text }}</a>
      <a slot="balance" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ parseFloat(text).toFixed(2) }}</a></a>
    </a-table>
  </div>
</template>

<script>

const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
    width: '10%'
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    scopedSlots: { customRender: 'username' }
  },
  {
    title: 'Nickname',
    dataIndex: 'nickname',
    key: 'nickname',
    width: '15%'
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    width: '15%'
  },
  {
    title: 'Wallettype',
    dataIndex: 'wallettype',
    width: '10%'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '10%'
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    width: '20%',
    scopedSlots: { customRender: 'balance' }
  }
]

export default {
  data () {
    return {
      columns,
      selectType: '',
      username: '',
      agent_id: '',
      myDownline: []
    }
  },
  created () {
    this.agent_id = this.$route.query.agent_id
    this.getData()
  },
  methods: {
    async getData () {
      try {
        const response = await this.axios.get('/1.0/agent/submydownline', { params: { agent_id: this.agent_id, username: this.username, type: this.selectType } })
        if (response.status === 200) {
          this.myDownline = response.data.map(data => data)
        }
      } catch (error) {
      }
    },
    async handleMemberTypeChange (value) {
      this.selectType = value
      this.getData()
    },
    async searchName () {
      this.getData()
    },
    member (val) {
      this.agent_id = val
      this.getData()
    }
  }
}
</script>

<style>
  .textbold {
    font-weight: bold;
  }
  .table-row-light {
    background-color: #ffffff;
  }
  .table-row-dark {
      background-color: #fbfbfb;
  }
</style>
