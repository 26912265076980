<template>
  <div class="h-screen w-full flex justify-center items-center bg-gradient-to-bl via-red-700 from-red-700 to-red-400">
    <div class="">
      <a-card style="width: 300px">
        <div style="margin: 0px 0px 20px 70px;">
          <img src="/kc690.webp" alt="logo" width="100">
        </div>
        <a-form layout="horizontal" :form="form" @submit="handleSubmit">
          <a-form-item :validate-status="userNameError() ? 'error' : ''" :help="userNameError() || ''">
            <a-input
              v-decorator="[
                'userName',
                { rules: [{ required: true, message: 'Please input your username!' }] },
              ]"
              placeholder="Username"
            >
              <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
            </a-input>
          </a-form-item>
          <a-form-item :validate-status="passwordError() ? 'error' : ''" :help="passwordError() || ''">
            <a-input
              v-decorator="[
                'password',
                { rules: [{ required: true, message: 'Please input your Password!' }] },
              ]"
              type="password"
              placeholder="Password"
            >
              <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-button style="margin-left: 90px;" type="primary" html-type="submit" :disabled="hasErrors(form.getFieldsError())">
              Log in
            </a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </div>
  </div>
</template>
<script>
import { loginUser } from '../utils/auth'
function hasErrors (fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}
export default {
  data () {
    return {
      hasErrors,
      form: this.$form.createForm(this, { name: 'horizontal_login' })
    }
  },
  mounted () {
    this.$nextTick(() => {
      // To disabled submit button at the beginning.
      this.form.validateFields()
      this.form.setFieldsValue({ userName: '', password: '' })
    })
  },
  methods: {
    // Only show error after a field is touched.
    userNameError () {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched('userName') && getFieldError('userName')
    },
    // Only show error after a field is touched.
    passwordError () {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched('password') && getFieldError('password')
    },
    async handleSubmit (e) {
      try {
        e.preventDefault()
        await this.form.validateFields(async (err, values) => {
          if (!err) {
            await loginUser(values.userName, values.password)
            // await getProfile()
            this.$router.push('/')
          }
        })
      } catch (error) {
      }
    }
  }
}
</script>
<style>
/* body {
  background: rgb(235, 234, 234) !important;
} */
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
</style>
