var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t('changepassword')))]),_vm._v(" "),_c('br'),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":_vm.$t('username')}},[_c('a-input',{attrs:{"disabled":"","placeholder":"Please input your name"},model:{value:(_vm.profile.username),callback:function ($$v) {_vm.$set(_vm.profile, "username", $$v)},expression:"profile.username"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":_vm.$t('oldpassword')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'oldpassword',
                    { rules: [{ required: true, message: 'Please input your Old Password!' }] },
                  ]),expression:"[\n                    'oldpassword',\n                    { rules: [{ required: true, message: 'Please input your Old Password!' }] },\n                  ]"}],attrs:{"type":"password","placeholder":_vm.$t('oldpassword')}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":_vm.$t('newpassword'),"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'newpassword',
                    {
                    rules: [
                        {
                          required: true,
                          message: 'Please input your New Password!'
                        },
                        {
                          validator: _vm.validateToNextPassword,
                        },
                      ]
                    },
                  ]),expression:"[\n                    'newpassword',\n                    {\n                    rules: [\n                        {\n                          required: true,\n                          message: 'Please input your New Password!'\n                        },\n                        {\n                          validator: validateToNextPassword,\n                        },\n                      ]\n                    },\n                  ]"}],attrs:{"type":"password","placeholder":_vm.$t('newpassword')}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":_vm.$t('confirmpassword'),"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'confirm',
                    {
                    rules: [
                        {
                          required: true,
                          message: 'Please input your Confirm Password!'
                        },
                        {
                          validator: _vm.compareToFirstPassword,
                        },
                      ]
                    },
                  ]),expression:"[\n                    'confirm',\n                    {\n                    rules: [\n                        {\n                          required: true,\n                          message: 'Please input your Confirm Password!'\n                        },\n                        {\n                          validator: compareToFirstPassword,\n                        },\n                      ]\n                    },\n                  ]"}],attrs:{"type":"password","placeholder":_vm.$t('confirmpassword')},on:{"blur":_vm.handleConfirmBlur}})],1)],1)])],1),_c('a-row',[_c('a-col',[_c('div',[_c('a-form-item',{attrs:{"label-col":_vm.formTailLayout.labelCol,"wrapper-col":_vm.formTailLayout.wrapperCol}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","disabled":_vm.hasErrors(_vm.form.getFieldsError())}},[_vm._v(" "+_vm._s(_vm.$t('savenewpassword'))+" ")])],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }