<template>
    <div>
        <a-row type="flex" justify="space-between" style="padding-top: 2%">
          <a-col :lg="16" :xs="24" :order="2">
            <span class="textbold">{{$t('date')}}</span><br />
            <a-range-picker
              format="YYYY/MM/DD"
              showTime
              @change="onChangeDate"
              @ok="selectDate"
              :value="[stardate, enddate]"
            />
          </a-col>
          <a-col :lg="4" :xs="24" :order="1">
            <span class="textbold">{{$t('type')}}</span> <br />
            <a-select default-value="all" style="width: 100%" @change="changeDateRange">
              <a-select-option value="all">
                {{$t('alltypes')}}
              </a-select-option>
              <a-select-option value="agent">
                {{$t('agent')}}
              </a-select-option>
              <a-select-option value="user">
                {{$t('user')}}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <br />
        <a-row type="flex" justify="space-between">
            <a-col :lg="24" :xs="24" :order="2" style="margin-top: 20px">
                <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="middle"
                :loading="loadingData"
                :scroll="{ x: 'calc(700px + 50%)', y: 300 }"
                :rowClassName="(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'"
                >
                <a slot="amount" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ text }}</a></a>
                </a-table>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'

const columns = [
  {
    title: 'Username',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: '15%'
  },
  {
    title: 'Nickname',
    dataIndex: 'nickname',
    key: 'nickname',
    align: 'center',
    width: '15%'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    width: '10%'
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    scopedSlots: { customRender: 'amount' }
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    align: 'center',
    width: '25%'
  }
]

const data = []

export default {
  data () {
    return {
      data,
      columns,
      loadingData: false,
      checkedList: [],
      stardate: moment(),
      enddate: moment()
    }
  },
  beforeCreate () {
    data.length = 0
  },
  async created  () {
    this.getData()
  },
  methods: {
    moment,
    async getData (type) {
      data.length = 0
      this.loadingData = true
      try {
        await axios.get('/1.0/agent/report/transfer', { params: { type: type, stardate: this.stardate, enddate: this.enddate } }).then((res) => {
          res.data.map((items, index) => {
            if (items.targettype === 'agent') {
              data.push({
                key: index + Math.random(),
                id: items._id,
                name: items.agent.username,
                nickname: items.agent.nickname,
                type: items.targettype,
                amount: items.amount,
                time: moment(items.createdAt).format('DD-MM-YYYY HH:MM:SS')
              })
            } else {
              data.push({
                key: index + Math.random(),
                id: items._id,
                name: items.user.username,
                nickname: items.user.nickname,
                type: items.targettype,
                amount: items.amount,
                time: moment(items.createdAt).format('DD-MM-YYYY HH:MM:SS')
              })
            }
          })
          this.loadingData = false
        })
      } catch (error) {
        this.loadingData = false
      }
    },
    onChangeDate (date, dateString) {
      if (dateString.length > 0) {
        this.stardate = dateString[0]
        this.enddate = dateString[1]
      } else {
        this.stardate = ''
        this.enddate = ''
      }
    },
    changeDateRange (value) {
      switch (value) {
        case 'agent':
          this.getData(value)
          break
        case 'user':
          this.getData(value)
          break
        case 'all':
          this.getData()
          break
        default:
          break
      }
    },
    selectDate () {
      if (this.username === '') {
        this.getData()
      } else {
        this.getData(this.username)
      }
    }
  }
}
</script>
<style>
  .textbold {
    font-weight: bold;
  }
  .table-row-light {
    background-color: #ffffff;
  }
  .table-row-dark {
      background-color: #fbfbfb;
  }
</style>
