<template>
  <div>
    <a-table :columns="columns" :data-source="subaccount" size="small" :scroll="{ x: 1000 }">
      <span slot="action" slot-scope="text, record">
        <a-button-group>
          <a-button type="primary" @click="showModaledit(record.id)">
            <a-icon type="edit" />
          </a-button>
          <a-popconfirm
            title="Are you sure delete this user ?"
            ok-text="Yes"
            cancel-text="No"
            placement="topRight"
            @confirm="deleteSub(record.id)"
          >
            <a-button type="danger">
              <a-icon type="delete" />
            </a-button>
          </a-popconfirm>
        </a-button-group>
      </span>
    </a-table>
    <a-modal
      title="Edit Sub Account"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="editSub"
      @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-row>
          <a-col :span="24">
              <div style="margin-top: 40px;">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                :label="$t('nickname')"
              >
                <a-input
                  :placeholder="$t('nickname')"
                  v-decorator="['nickname']"
                />
              </a-form-item>
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                :label="$t('phonenumber')"
              >
                <a-input
                  :placeholder="$t('phonenumber')"
                  v-decorator="['phone']"
                />
              </a-form-item>
            </div>
              <div>
                <span style="font-weight: bold;">{{$t('permissions')}}</span> <br/>
                <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" :label="$t('membermgm')">
                  <a-radio-group v-for="(i,index) in permission" :key="index" v-model="permission_membermgm">
                    <a-radio :value="i.value">
                      <span :style="{color: `${i.color}`}">{{i.title}}</span>
                    </a-radio>
                  </a-radio-group>
                </a-form-item>
                <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" :label="$t('report')">
                  <a-radio-group v-for="(i,index) in permission" :key="index" v-model="permission_report">
                    <a-radio :value="i.value">
                      <span :style="{color: `${i.color}`}">{{i.title}}</span>
                    </a-radio>
                  </a-radio-group>
                </a-form-item>
                <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" :label="$t('deposit')">
                  <a-radio-group v-for="(i,index) in permission" :key="index" v-model="permission_deposit">
                    <a-radio :value="i.value">
                      <span :style="{color: `${i.color}`}">{{i.title}}</span>
                    </a-radio>
                  </a-radio-group>
                </a-form-item>
                <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="API Key">
                  <a-radio-group v-for="(i,index) in permission" :key="index" v-model="permission_api">
                    <a-radio :value="i.value">
                      <span :style="{color: `${i.color}`}">{{i.title}}</span>
                    </a-radio>
                  </a-radio-group>
                </a-form-item>
              </div>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { sync } from 'vuex-pathify'

function hasErrors (fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const columns = [
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username'
  },
  {
    title: 'Nickname',
    dataIndex: 'Nickname',
    width: '25%'
  },
  {
    title: 'Phone',
    dataIndex: 'Phone',
    width: '25%'
  },
  {
    title: 'Edited/Delete',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    width: '15%'
  }
]

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 }
}
const formTailLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 3, offset: 10 }
}

export default {
  data () {
    return {
      columns,
      subaccount: [],
      visible: false,
      confirmLoading: false,
      idSecreted: '',
      formItemLayout,
      formTailLayout,
      permission_membermgm: 'close',
      permission_report: 'close',
      permission_deposit: 'close',
      permission_api: 'close',
      hasErrors,
      permission: [{ title: 'ปิด', value: 'close', color: 'red' }, { title: 'มีสิทธิ์ดู', value: 'open', color: 'orange' }, { title: 'มีสิทธิ์แก้ไข', value: 'edit', color: 'green' }],
      form: this.$form.createForm(this, { name: 'dynamic_rule' })
    }
  },
  created () {
    this.fetchSubAccount()
  },
  computed: {
    hasError: sync('member/hasError'),
    messageApi: sync('member/message')
  },
  methods: {
    async fetchSubAccount () {
      this.subaccount = []
      try {
        const response = await axios.get('/1.0/agent/subaccount')
        if (response.status === 200) {
          response.data.subaccount.map((items, index) => {
            this.subaccount.push({
              key: index + Math.random(),
              id: items._id,
              username: items.username,
              Nickname: items.nickname,
              Phone: items.phone,
              permissions: items.permissions
            })
          })
        }
      } catch (error) {
        this.subaccount = []
      }
    },
    async showModaledit (id) {
      this.idSecreted = id
      this.visible = true
      const account = await this.subaccount.filter((items) => items.id === id)
      if (account[0].Nickname != null) {
        this.form.setFieldsValue({
          nickname: (await account[0].Nickname) || ''
        })
      }
      if (account[0].Phone != null) {
        this.form.setFieldsValue({
          phone: (await account[0].Phone) || ''
        })
      }
      this.permission_membermgm = account[0].permissions.member_mgm
      this.permission_report = account[0].permissions.report
      this.permission_deposit = account[0].permissions.deposit
      this.permission_api = account[0].permissions.api_key
    },
    handleCancel (e) {
      this.visible = false
      this.confirmLoading = false
    },
    async editSub () {
      this.confirmLoading = true
      try {
        const permissions = {
          member_mgm: this.permission_membermgm,
          report: this.permission_report,
          deposit: this.permission_deposit,
          api_key: this.permission_api
        }
        const value = {
          sub_id: this.idSecreted,
          nickname: this.form.getFieldValue('nickname'),
          phone: this.form.getFieldValue('phone'),
          permissions
        }
        const response = await axios.put(
          '/1.0/agent/subaccount',
          value,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        if (response.status === 200) {
          this.confirmLoading = false
          this.visible = false
          this.messageApi = response.data.message
          this.hasError = false
          this.fetchSubAccount()
        }
      } catch (error) {
        this.messageApi = error.response.data.message
        this.hasError = true
        this.confirmLoading = false
      }
      if (this.hasError) {
        this.$message.error(this.messageApi)
      } else {
        this.$message.success(this.messageApi)
      }
    },
    async deleteSub (id) {
      try {
        const response = await axios.delete('/1.0/agent/subaccount', { params: { sub_id: id } })
        if (response.status === 200) {
          this.messageApi = response.data.message
          this.hasError = false
          this.fetchSubAccount()
        }
      } catch (error) {
        this.messageApi = error.response.data.message
        this.hasError = true
      }
      if (this.hasError) {
        this.$message.error(this.messageApi)
      } else {
        this.$message.success(this.messageApi)
      }
    }
  }
}
</script>

<style></style>
