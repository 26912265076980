<template>
  <div>
    <a-table :columns="columns" :data-source="data" size="small" :scroll="{ x: 500 }">
    </a-table>
  </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'

const columns = [
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    width: '20%'
  },
  {
    title: 'Secret Key',
    dataIndex: 'secretkey',
    key: 'secretkey'
  },
  {
    title: 'Date Time',
    dataIndex: 'date',
    key: 'date',
    width: '20%'
  }
]

export default {
  data () {
    return {
      data: [],
      columns
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      this.data = []
      try {
        const response = await axios.get('/1.0/agent/transfer')
        if (response.status === 200) {
          response.data.agents.map((items) => {
            this.data.push({
              key: items._id,
              username: items.username,
              secretkey: items.secret_key,
              date: moment(items.createdAt).format('DD-MM-YYYY HH:MM:SS')
            })
          })
        }
      } catch (error) {
        this.data = []
      }
    }
  }
}
</script>
