import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'ant-design-vue/dist/antd.css'
import './assets/ant/main.less'
import './assets/main.css'
import Antd from 'ant-design-vue'
import i18n from './plugins/i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import dotenv from 'dotenv'

dotenv.config()
axios.defaults.baseURL = process.env.VUE_APP_API_URL
Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(Antd)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
