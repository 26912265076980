<template>
  <div>
    <div class="flex flex-row items-center w-1/3 py-2 space-x-2">
      <span>Username</span>
      <a-input placeholder="username" v-model="username" />
      <a-button type="primary" @click="searchName">
        {{$t('search')}}
      </a-button>
    </div>
    <a-table :columns="columns" :data-source="myDownline" rowKey="_id" size="small" :scroll="{ x: 700 }">
      <span slot="tags" slot-scope="tags">
      <a-tag
        v-for="tag in tags"
        :key="tag"
        :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
        style="margin-bottom: 1%;"
      >
        {{ tag.toUpperCase() }}
      </a-tag>
    </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="primary" @click="productsDownline(record._id)">
          <a-icon type="plus-circle" />
        </a-button>
      </span>
    </a-table>
    <a-modal
      :title="$t('addeditgames')"
      :visible="visible"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="handleCancel">
          {{$t('cancel')}}
        </a-button>
        <a-button type="primary" :loading="confirmLoading" @click="handleOk">
          {{$t('save')}}
        </a-button>
      </template>
      <template v-if="product_list.length === 0">
        <a-empty />
      </template>
      <a-checkbox-group v-else @change="onChange" :value="select_product" style="width: 100%;">
        <a-row :gutter="[32,16]">
          <template v-for="(product, index) in product_list">
            <a-col :span="12" :key="index" >
              <a-checkbox :value="product.value">
                {{product.label}}
              </a-checkbox>
            </a-col>
          </template>
        </a-row>
      </a-checkbox-group>
    </a-modal>
  </div>
</template>
<script>
import { sync } from 'vuex-pathify'

function hasErrors (fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const columns = [
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    width: '20%'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '15%'
  },
  {
    title: 'Games',
    key: 'games',
    dataIndex: 'games',
    scopedSlots: { customRender: 'tags' }
  },
  {
    title: 'Add/Edit Games',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    width: '15%'
  }
]

export default {
  data () {
    return {
      username: '',
      columns,
      myDownline: [],
      hasErrors,
      visible: false,
      confirmLoading: false,
      product_list: [],
      select_product: [],
      select_userId: ''
    }
  },
  computed: {
    hasError: sync('member/hasError'),
    messageApi: sync('member/message')
  },
  async created () {
    await this.fetchMyDownline()
  },
  methods: {
    async fetchMyDownline () {
      try {
        const response = await this.axios.get('/1.0/agent/downline', { params: { username: this.username } })
        if (response.status === 200) {
          this.myDownline = response.data.agents.map((items) => {
            return {
              _id: items._id,
              username: items.username,
              status: items.status,
              games: items.agentproducts ? items.agentproducts[0].productId : []
            }
          })
        }
      } catch (error) {
      }
    },
    async getProduct () {
      try {
        const response = await this.axios.get('/1.0/agent/myproducts')
        if (response.status === 200) {
          this.product_list = response.data.products.map((items) =>
            ({
              label: items.name,
              value: items.productId
            })
          )
        }
      } catch (error) {
      }
    },
    async productsDownline (id) {
      this.visible = true
      this.select_userId = id
      try {
        const response = await this.axios.get('/1.0/agent/gamesdownline', { params: { user_id: id } })
        if (response.status === 200) {
          this.select_product = response.data.products.productId
        }
      } catch (error) {
      }
      await this.getProduct()
    },
    async searchName () {
      await this.fetchMyDownline({
        username: this.username
      })
    },
    onChange (checkedValues) {
      this.select_product = checkedValues
    },
    async handleOk (e) {
      this.confirmLoading = true
      try {
        const value = {
          user_id: this.select_userId,
          product: this.select_product
        }
        const response = await this.axios.post(
          '/1.0/agent/gamesdownline',
          value,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          })
        if (response.status === 200) {
          this.messageApi = response.data.message
          this.hasError = false
          this.confirmLoading = false
          this.visible = false
          await this.fetchMyDownline()
        }
      } catch (error) {
        this.messageApi = error.response.data.message
        this.hasError = true
        this.confirmLoading = false
      }
      if (this.hasError) {
        this.$message.error(this.messageApi)
      } else {
        this.$message.success(this.messageApi)
      }
    },
    handleCancel (e) {
      this.visible = false
    }
  }
}
</script>
