<template>
  <a-layout id="components-layout-demo-fixed-sider">
    <a-layout>
      <a-layout-sider
      breakpoint="lg"
      collapsed-width="0"
      theme="dark"
      v-model="collapsed"
      @breakpoint="onBreakpoint"
      :style="collapsed ? {} : { height: '100vh', position: 'fixed', left: 0 }"
      >
      <div class="logo" />
        <a-menu theme="dark" mode="inline" :default-selected-keys="['1']">
          <a-menu-item @click="changerount('/')" key="1">
            <a-icon type="layout" />
            <span class="nav-text">{{$t('dashboard')}}</span>
          </a-menu-item>
          <a-sub-menu key="2">
            <span slot="title"><a-icon type="solution" />{{$t('account')}}</span>
            <a-menu-item key="3" @click="changerount('/user/profile')" >
              {{$t('profile')}}
            </a-menu-item>
            <a-menu-item key="4" @click="changerount('/user/password')">
              {{$t('password')}}
            </a-menu-item>
          </a-sub-menu>
          <!-- <a-sub-menu key="21">
            <span slot="title"><a-icon type="solution" />{{$t('event')}}</span>
            <a-menu-item key="22" @click="changerount('/events/all')" >
              {{$t('eventall')}}
            </a-menu-item>
          </a-sub-menu> -->
          <a-sub-menu key="5">
            <span slot="title"><a-icon type="user" />{{$t('membermgm')}}</span>
            <a-menu-item key="6" @click="changerount('/member/addagent')">
              {{$t('addagent')}}
            </a-menu-item>
            <a-menu-item key="7" @click="changerount('/member/addmember')">
              {{$t('addmember')}}
            </a-menu-item>
            <a-menu-item key="26" @click="changerount('/member/agentcommission')">
              {{$t('agentcommission')}}
            </a-menu-item>
            <a-menu-item key="8" @click="changerount('/member/memberagent')">
              {{$t('memberagents')}}
            </a-menu-item>
            <a-menu-item key="9" @click="changerount('/member/addsubaccount')">
              {{$t('addsubaccount')}}
            </a-menu-item>
            <a-menu-item key="10" @click="changerount('/member/subaccount')">
              {{$t('subaccounts')}}
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="23">
            <span slot="title"><a-icon type="profile" />{{$t('product')}}</span>
            <a-menu-item @click="changerount('/games/downline')" key="25">
              {{$t('openclosegames')}}
            </a-menu-item>
            <a-menu-item @click="changerount('/games/all')" key="24">
              {{$t('productall')}}
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="11">
            <span slot="title"><a-icon type="profile" />{{$t('report')}}</span>
            <a-menu-item @click="changerount('/report/all')" key="12">
              {{$t('reportall')}}
            </a-menu-item>
            <a-menu-item @click="changerount('/report/mymember')" key="27">
              {{$t('reportmember')}}
            </a-menu-item>
            <a-menu-item @click="changerount('/report/reporttransfer')" key="28">
              {{$t('reporttransfer')}}
            </a-menu-item>
            <!-- <a-menu-item key="13">
              {{$t('wlmember')}}
            </a-menu-item> -->
          </a-sub-menu>
          <!-- <a-sub-menu key="14">
            <span slot="title"><a-icon type="profile" />{{$t('payment')}}</span>
            <a-menu-item key="15">
              {{$t('depowithdraw')}}
            </a-menu-item>
          </a-sub-menu> -->
          <!-- <a-sub-menu key="16">
            <span slot="title"><a-icon type="profile" />{{$t('suggestion')}}</span>
            <a-menu-item key="17">
              {{$t('member')}}
            </a-menu-item>
          </a-sub-menu> -->
          <template v-if="profile.isapi">
            <a-sub-menu key="18">
              <span slot="title"><a-icon type="profile" />{{$t('apikey')}}</span>
              <!-- <a-menu-item key="19">
                {{$t('seamless')}}
              </a-menu-item> -->
              <a-menu-item @click="changerount('/apikey/transfer')" key="20">
                {{$t('transfer')}}
              </a-menu-item>
            </a-sub-menu>
          </template>
        </a-menu>
        <div :style="{color: 'white', marginTop: '30px'}" v-if="breakpoint">
          <span style="margin-left: 15px; text-transform: uppercase;"><a-icon type="user" /> {{ profile.username}}</span> <br>
          <span style="margin-left: 15px; margin-top: 50px; text-transform: uppercase;"><a-icon type="idcard" /> {{profile.type}}</span> <br>
          <span style="margin-left: 15px; margin-top: 50px;"><a-icon type="bank" /> {{profile.balance}} {{profile.currency}}</span>
        </div>
      </a-layout-sider>
      <a-layout>
        <a-layout-header class="bg-gradient-to-br via-red-700 from-red-700 to-red-400" :style="{position: 'fixed', zIndex: 1, width: '100%', color: 'white'}">
          <a-row type="flex" class="justify-center md:justify-between" >
            <a-col>
              <div v-if="!breakpoint">
                <span style="margin-right: 15px; text-transform: uppercase;"><a-icon type="user" /> {{profile.username}}</span>
                <span style="margin-right: 15px; text-transform: uppercase;"><a-icon type="idcard" /> {{profile.type}}</span>
                <span><a-icon type="bank" /> {{profile.balance}} {{profile.currency}}</span>
              </div>
            </a-col>
            <a-col>
                <!-- <a-row type="flex"> -->
                  <!-- <a-col :lg="5"> -->
                    <div>
                      <a-dropdown>
                        <a-menu slot="overlay">
                          <a-menu-item v-for="lang in languages" :key="lang.title" @click="changeLocale(lang.language)">
                            <span>{{lang.title}}</span>
                            <a-avatar :style="{marginLeft: '20px'}" :src="lang.image" />
                          </a-menu-item>
                        </a-menu>
                        <a-button type="link" ghost>
                          <a-avatar v-if="flag=='en'" :src="require('../assets/en.png')"></a-avatar>
                          <a-avatar v-if="flag=='th'" :src="require('../assets/th.png')"></a-avatar>
                          </a-button>
                      </a-dropdown>
                      <a-button ghost @click="logout">
                        {{$t('logout')}}
                      </a-button>
                    </div>
                  <!-- </a-col> -->
                <!-- </a-row> -->
            </a-col>
          </a-row>
        </a-layout-header>
        <div style="margin: 72px 0 0 16px">
          <!-- {{ $route.matched[0].name }}
          {{ $route.matched[1].name }} -->
        <a-breadcrumb v-if="$route.path !== '/'">
          <a-breadcrumb-item v-if="$route.matched[0]">{{ $route.matched[0].name }}</a-breadcrumb-item>
          <a-breadcrumb-item v-if="$route.matched[1]">{{ $route.matched[1].name }}</a-breadcrumb-item>
        </a-breadcrumb>
        </div>
        <a-layout-content :style="collapsed ? {} : { paddingLeft: '200px', margin: '10px 16px 0', overflow: 'initial' }">
          <div :style="{ padding: '24px', background: '#fff'}" v-if="$route.path !== '/'">
            <router-view/>
          </div>
          <div v-else>
            <router-view/>
          </div>
        </a-layout-content>
        <a-layout-footer :style="{textAlign: 'center'}">King Club69</a-layout-footer>
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script>
import { get, call } from 'vuex-pathify'
import { clearAuthToken } from '../utils/auth'

import i18n from '@/plugins/i18n'
export default {
  name: 'Home',
  data () {
    return {
      collapsed: false,
      breakpoint: false,
      flagBtn: '',
      languages: [
        { language: 'en', title: 'English', image: require('../assets/en.png') },
        { language: 'th', title: 'ไทย', image: require('../assets/th.png') }
      ]
    }
  },
  computed: {
    profile: get('account/profile'),
    flag () {
      if (localStorage.getItem('languages') !== null) {
        i18n.locale = localStorage.getItem('languages')
      } else {
        i18n.locale = 'en'
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.flagBtn = i18n.locale
      return this.flagBtn
    }
  },
  async created () {
    await this.myProfile()
  },
  methods: {
    myProfile: call('account/myProfile'),
    deleteProfile: call('logout'),
    logout () {
      clearAuthToken()
      this.deleteProfile()
      this.$router.push('/login')
    },
    changerount (path) {
      this.$router.push(`${path}`).catch(() => {})
    },
    changeLocale (locale) {
      i18n.locale = locale
      localStorage.setItem('languages', locale)
    },
    onBreakpoint (broken) {
      this.breakpoint = broken
    }
  }
}
</script>
<style>
#components-layout-demo-fixed-sider .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.ant-layout-sider-zero-width-trigger {
  top: 103px !important;
}
</style>
