<template>
  <div>
    <a-modal v-model="visibleModelDeposit" title="Deposit" @ok="handleDeposit">
      <a-form-model :model="formDeposit">
        <a-form-model-item
          label="amount"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-input v-model="formDeposit.amount" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      v-model="visibleModelWithdraw"
      title="Withdraw"
      @ok="handleWithdraw"
    >
      <a-form-model :model="formWithdraw">
        <a-form-model-item
          label="amount"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-input v-model="formWithdraw.amount" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <div class="flex flex-row items-center w-1/3 py-2 space-x-2">
      <span>Username</span>
      <a-input placeholder="username" v-model="username" />
      <span>{{$t('type')}}</span>
      <a-select
        default-value=""
        style="width: 100%"
        @change="handleMemberTypeChange"
      >
        <a-select-option value="">
          {{$t('all')}}
        </a-select-option>
        <a-select-option value="agent">
          {{$t('agent')}}
        </a-select-option>
        <a-select-option value="user">
          {{$t('member')}}
        </a-select-option>
      </a-select>

      <a-button type="primary" @click="searchName">
        {{$t('search')}}
      </a-button>
    </div>
    <a-table :columns="columns" :data-source="myDownline" rowKey="_id" size="small" :scroll="{ x: 1000 }" :rowClassName="(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'">
      <span slot="action" slot-scope="text, record">
        <a-button-group>
          <a-button type="primary" @click="testDeposit(record._id)">
            <a-icon type="plus-circle" />
          </a-button>
          <a-button type="danger" @click="testWithdraw(record._id)">
            <a-icon type="minus-circle" />
          </a-button>
        </a-button-group>
      </span>
      <span slot="edit" slot-scope="text, record">
        <a-button-group>
          <a-button type="primary" @click="editData(record._id)">
            <a-icon type="edit" />
          </a-button>
        </a-button-group>
      </span>
      <a v-if="record.type === 'agent'" slot="username" slot-scope="text, record" @click="() => member(record._id)">{{ text }}</a>
      <a v-else slot="username" slot-scope="text">{{ text }}</a>
      <a slot="balance" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ parseFloat(text).toFixed(2) }}</a></a>
    </a-table>
    <a-modal
      :title="$t('editdata')"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :footer="null"
      width='70%'
      @cancel="handleCancel"
    >
      <div>
        <template v-if="formuser">
          <editMember v-bind:datauser="userData" v-bind:visible="handleCancel" v-bind:fetchMyDownline="fetchMyDownline"/>
        </template>
        <template v-else>
          <editAgent v-bind:datauser="userData" v-bind:visible="handleCancel" v-bind:fetchMyDownline="fetchMyDownline"/>
        </template>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { formItemLayout, formTailLayout } from '../../utils/form_size'
import { get, call } from 'vuex-pathify'
import axios from 'axios'
import editMember from './editMember.vue'
import editAgent from './editAgent.vue'

const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
    width: '10%'
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    width: '15%',
    scopedSlots: { customRender: 'username' }
  },
  {
    title: 'Nickname',
    dataIndex: 'nickname',
    key: 'nickname',
    width: '10%'
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: 'Wallettype',
    dataIndex: 'wallettype',
    width: '10%'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '10%'
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    scopedSlots: { customRender: 'balance' }
  },
  {
    title: 'Refill/Deduct',
    key: 'action',
    width: '13%',
    scopedSlots: { customRender: 'action' }
  },
  {
    title: 'Edit Data',
    key: 'edit',
    width: '8%',
    scopedSlots: { customRender: 'edit' }
  }
]

export default {
  data () {
    return {
      formItemLayout,
      formTailLayout,
      columns,
      selectType: '',
      username: '',
      usernameSecreted: undefined,
      formDeposit: {
        amount: 0
      },
      visibleModelDeposit: false,
      formWithdraw: {
        amount: 0
      },
      visibleModelWithdraw: false,
      visible: false,
      confirmLoading: false,
      userData: [],
      formuser: false
    }
  },
  components: {
    editMember,
    editAgent
  },
  created () {
    this.fetchMyDownline()
  },
  computed: {
    myDownline: get('member/myDownline')
  },
  methods: {
    fetchMyDownline: call('member/fetchMyDownline'),
    editData (id) {
      this.visible = true
      const editUser = this.myDownline.filter(user => user._id === id)
      if (editUser[0].type === 'user') {
        this.formuser = true
      } else {
        this.formuser = false
      }
      this.userData = editUser
    },
    handleCancel (e) {
      this.visible = false
    },
    testDeposit (_value) {
      this.usernameSecreted = _value
      this.visibleModelDeposit = true
    },
    testWithdraw (_value) {
      this.usernameSecreted = _value
      this.visibleModelWithdraw = true
    },
    async handleDeposit () {
      await this.handleDepositApi(
        this.usernameSecreted,
        this.formDeposit.amount
      )
      this.visibleModelDeposit = false
      this.usernameSecreted = undefined
      this.formDeposit.amount = 0
    },
    async handleWithdraw () {
      await this.handleWithdrawtApi(
        this.usernameSecreted,
        this.formWithdraw.amount
      )
      this.visibleModelWithdraw = false
      this.usernameSecreted = undefined
      this.formDeposit.amount = 0
    },
    async handleDepositApi (username, amount) {
      try {
        await axios.post(
          '/1.0/agent/deposit?_id=' + username,
          {
            amount: parseInt(amount)
          }
        )
        await this.fetchMyDownline()
      } catch (error) {
      }
    },
    async handleWithdrawtApi (username, amount) {
      try {
        await axios.post(
          '/1.0/agent/withdraw?_id=' + username,
          {
            amount: parseInt(amount)
          }
        )
        await this.fetchMyDownline()
      } catch (error) {
      }
    },
    async handleMemberTypeChange (value) {
      this.selectType = value
      await this.fetchMyDownline({ type: value })
    },
    async searchName () {
      await this.fetchMyDownline({
        username: this.username,
        type: this.selectType
      })
    },
    member (val) {
      this.$router.push({ path: '/member/submember', query: { agent_id: val } })
    }
  }
}
</script>

<style>
  .textbold {
    font-weight: bold;
  }
  .table-row-light {
    background-color: #ffffff;
  }
  .table-row-dark {
      background-color: #fbfbfb;
  }
</style>
