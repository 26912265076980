import { make } from 'vuex-pathify'
import axios from 'axios'
import { isLoggedIn } from '../utils/auth'

// properties
const state = {
  profile: {
    balance: 0,
    type: '',
    status: '',
    username: '',
    nickname: '',
    commission: {
      max: 0
    }
  }
}

const actions = {
  async myProfile ({ commit }) {
    try {
      if (isLoggedIn()) {
        const { data } = await axios.get('/1.0/agent/myprofile')
        // state.profile = data
        commit('SET_PROFILE', data)
      }
    } catch (error) {
    }
  },
  logout () {
    state.profile = {}
  }
}

const mutations = {
  ...make.mutations(state),
  SET_PROFILE (state, value) {
    state.profile = value
  }
}

const getters = {
  profile (state) {
    return state.profile
  },
  balance (state) {
    return state.profile.balance || 0
  },
  commissionMax (state) {
    return state.profile.commission.max || 0
  }
}

// store
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
