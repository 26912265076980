import axios from 'axios'

const AUTH_TOKEN_KEY = 'authToken'

export function loginUser (username, password) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post('/1.0/agent/login', {
        username: username,
        password: password
      })
      setAuthToken(data.token)
      resolve()
    } catch (err) {
      console.error('Caught an error during login:', err)
      reject(err)
    }
  })
}

export function logoutUser () {
  clearAuthToken()
}

export function setAuthToken (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
  localStorage.setItem(AUTH_TOKEN_KEY, token)
}

export function getAuthToken () {
  return localStorage.getItem(AUTH_TOKEN_KEY)
}

export function clearAuthToken () {
  axios.defaults.headers.common.Authorization = ''
  localStorage.removeItem(AUTH_TOKEN_KEY)
}

export function isLoggedIn () {
  const authToken = getAuthToken()
  return !!authToken
}
