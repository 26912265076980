<template>
  <div>
    <!-- User Page -->
    <router-view/>
  </div>
</template>
<script>
export default {
  created () {
  }
}
</script>
