<template>
  <div>
      <a-form :form="form" @submit="handleSubmit">
        <a-row>
          <a-col :span="12">
            <span style="font-weight: bold;">{{$t('changepassword')}}</span> <br/>
              <div style="margin-top: 20px;">
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  :label="$t('username')"
                >
                  <a-input
                    disabled
                    v-model="profile.username"
                    placeholder="Please input your name"
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  :label="$t('oldpassword')"
                >
                  <a-input
                    v-decorator="[
                      'oldpassword',
                      { rules: [{ required: true, message: 'Please input your Old Password!' }] },
                    ]"
                    type="password"
                    :placeholder="$t('oldpassword')"
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  :label="$t('newpassword')"
                  has-feedback
                >
                  <a-input
                    v-decorator="[
                      'newpassword',
                      {
                      rules: [
                          {
                            required: true,
                            message: 'Please input your New Password!'
                          },
                          {
                            validator: validateToNextPassword,
                          },
                        ]
                      },
                    ]"
                    type="password"
                    :placeholder="$t('newpassword')"
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  :label="$t('confirmpassword')"
                  has-feedback
                >
                  <a-input
                    v-decorator="[
                      'confirm',
                      {
                      rules: [
                          {
                            required: true,
                            message: 'Please input your Confirm Password!'
                          },
                          {
                            validator: compareToFirstPassword,
                          },
                        ]
                      },
                    ]"
                    type="password"
                    @blur="handleConfirmBlur"
                    :placeholder="$t('confirmpassword')"
                  />
                </a-form-item>
              </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <div>
              <a-form-item :label-col="formTailLayout.labelCol" :wrapper-col="formTailLayout.wrapperCol">
                <a-button type="primary" html-type="submit" :disabled="hasErrors(form.getFieldsError())">
                  {{$t('savenewpassword')}}
                </a-button>
              </a-form-item>
            </div>
          </a-col>
        </a-row>
      </a-form>
  </div>
</template>
<script>
import { get } from 'vuex-pathify'
import axios from 'axios'

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 11 }
}
const formTailLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 3, offset: 4 }
}
function hasErrors (fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

export default {
  data () {
    return {
      hasErrors,
      confirmDirty: false,
      checkNick: false,
      formItemLayout,
      formTailLayout
      // newpassword: '',
      // confirmpassword: '',
      // form: this.$form.createForm(this, { name: 'dynamic_rule' })
    }
  },
  computed: {
    profile: get('account/profile')
  },
  mounted () {
    this.$nextTick(() => {
      // To disabled submit button at the beginning.
      this.form.validateFields()
    })
  },
  beforeCreate () {
    this.form = this.$form.createForm(this, { name: 'newpassword' })
  },
  methods: {
    compareToFirstPassword (rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('newpassword')) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('Two passwords that you enter is inconsistent!')
      } else {
        callback()
      }
    },
    validateToNextPassword (rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    // check () {
    //   this.form.validateFields(err => {
    //     if (!err) {
    //       console.info('success')
    //     }
    //   })
    // },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          try {
            await axios.post('/1.0/agent/changpassword',
              {
                old_password: values.oldpassword,
                new_password: values.newpassword
              },
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            ).then((res) => {
              this.$success({
                title: res.data.message
              })
            })
          } catch (error) {
            this.$error({
              title: 'This is an error message',
              content: error.response.data.message
            })
          }
        }
      })
      this.form.resetFields()
    },
    handleConfirmBlur (e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    }
  }
}
</script>
