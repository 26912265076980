import Vue from 'vue'
import VueRouter from 'vue-router'
import { isLoggedIn } from '../utils/auth'
import axios from 'axios'
// page
import Home from '../views/Home.vue'
import Report from '../views/Report.vue'
import User from '../views/User.vue'
import Member from '../views/Member.vue'
import Login from '../views/Login.vue'
import Event from '../views/Event.vue'
import Apikey from '../views/Apikey.vue'
import Product from '../views/Product.vue'
// components
import reportAll from '@/components/report/reportAll.vue'
import reportMember from '@/components/report/reportMember.vue'
import profile from '@/components/user/profile.vue'
import password from '@/components/user/password.vue'
import addagent from '@/components/member/addAgent.vue'
import addmember from '@/components/member/addMember.vue'
import addsubaccount from '@/components/member/addSubaccount.vue'
import memberagent from '@/components/member/memberAgent.vue'
import subaccount from '@/components/member/subAccount.vue'
import eventall from '@/components/event/event.vue'
import transfer from '@/components/apikey/transfer.vue'
import productall from '@/components/product/productall.vue'
import productdownline from '@/components/product/productuser.vue'
import agentcommission from '@/components/member/agentCommission.vue'
import reportMyMember from '@/components/report/reportMyMember.vue'
import reportTransfer from '@/components/report/reportTransfer.vue'
import subMember from '@/components/member/subMember.vue'

// layout
import theme1 from '../layout/theme1.vue'
import logintheme from '../layout/login.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Home,
    meta: {
      title: 'Dashboard',
      layout: theme1,
      allowAnonymous: false
    }
  },
  {
    path: '/login',
    name: 'Dashboard',
    component: Login,
    meta: {
      title: 'Dashboard',
      layout: logintheme,
      allowAnonymous: true
    }
  },
  {
    path: '/report',
    component: Report,
    name: 'Report',
    meta: {
      title: 'Report',
      layout: theme1,
      allowAnonymous: false
    },
    children: [
      {
        path: 'all',
        component: reportAll,
        name: 'Report All',
        meta: {
          title: 'Report All',
          layout: theme1,
          allowAnonymous: false
        }
      },
      {
        path: 'member',
        component: reportMember,
        name: 'Member',
        meta: {
          title: 'Member',
          layout: theme1,
          allowAnonymous: false
        }
      },
      {
        path: 'mymember',
        component: reportMyMember,
        name: 'My Member',
        meta: {
          title: 'My Member',
          layout: theme1,
          allowAnonymous: false
        }
      },
      {
        path: 'reporttransfer',
        component: reportTransfer,
        name: 'Report Transfer',
        meta: {
          title: 'Report Transfer',
          layout: theme1,
          allowAnonymous: false
        }
      }
    ]
  },
  {
    path: '/user',
    component: User,
    name: 'User',
    meta: {
      title: 'User',
      layout: theme1,
      allowAnonymous: false
    },
    children: [
      {
        path: 'profile',
        component: profile,
        name: 'Profile',
        meta: {
          title: 'Profile',
          layout: theme1,
          allowAnonymous: false
        }
      },
      {
        path: 'password',
        component: password,
        name: 'Change Password',
        meta: {
          title: 'Password',
          layout: theme1,
          allowAnonymous: false
        }
      }
    ]
  },
  {
    path: '/member',
    component: Member,
    name: 'Member',
    meta: {
      title: 'Member',
      layout: theme1,
      allowAnonymous: false
    },
    children: [
      {
        path: 'addagent',
        component: addagent,
        name: 'Add Agent',
        meta: {
          title: 'Add Agent',
          layout: theme1,
          allowAnonymous: false
        }
      },
      {
        path: 'addmember',
        component: addmember,
        name: 'Add Member',
        meta: {
          title: 'Add Member',
          layout: theme1,
          allowAnonymous: false
        }
      },
      {
        path: 'addsubaccount',
        component: addsubaccount,
        name: 'Add Sub Account',
        meta: {
          title: 'Add Sub Account',
          layout: theme1,
          allowAnonymous: false
        }
      },
      {
        path: 'memberagent',
        component: memberagent,
        name: 'Member & Agents',
        meta: {
          title: 'Member & Agents',
          layout: theme1,
          allowAnonymous: false
        }
      },
      {
        path: 'subaccount',
        component: subaccount,
        name: 'Sub Accounts',
        meta: {
          title: 'Sub Accounts',
          layout: theme1,
          allowAnonymous: false
        }
      },
      {
        path: 'agentcommission',
        component: agentcommission,
        name: 'Agent Commission',
        meta: {
          title: 'Agent Commission',
          layout: theme1,
          allowAnonymous: false
        }
      },
      {
        path: 'submember',
        component: subMember,
        name: 'Member & Agents',
        meta: {
          title: 'Member & Agents',
          layout: theme1,
          allowAnonymous: false
        }
      }
    ]
  },
  {
    path: '/events',
    component: Event,
    name: 'Event',
    meta: {
      title: 'Event',
      layout: theme1,
      allowAnonymous: false
    },
    children: [
      {
        path: 'all',
        component: eventall,
        name: 'Event All',
        meta: {
          title: 'Event All',
          layout: theme1,
          allowAnonymous: false
        }
      }
    ]
  },
  {
    path: '/apikey',
    component: Apikey,
    name: 'Api Key',
    meta: {
      title: 'Api Key',
      layout: theme1,
      allowAnonymous: false
    },
    children: [
      {
        path: 'transfer',
        component: transfer,
        name: 'Transfer',
        meta: {
          title: 'Transfer',
          layout: theme1,
          allowAnonymous: false
        }
      }
    ]
  },
  {
    path: '/games',
    component: Product,
    name: 'Games',
    meta: {
      title: 'Games',
      layout: theme1,
      allowAnonymous: false
    },
    children: [
      {
        path: 'downline',
        component: productdownline,
        name: 'Downline',
        meta: {
          title: 'Downline',
          layout: theme1,
          allowAnonymous: false
        }
      },
      {
        path: 'all',
        component: productall,
        name: 'Show Game All',
        meta: {
          title: 'Show Game All',
          layout: theme1,
          allowAnonymous: false
        }
      }
    ]
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'login' && isLoggedIn()) {
    next({ path: '/' })
  } else if (!to.meta.allowAnonymous && !isLoggedIn()) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  } else {
    // setAuthToken()
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'authToken'
    )}`
    next()
  }
})
export default router
