<template>
  <div class="home">
    <a-row type="flex" class="flex-wrap" justify="space-around">
      <a-col class="w-full md:w-1/2 p-2">
        <!-- <a-carousel arrows>
          <div
            slot="prevArrow"
            class="custom-slick-arrow"
            style="left: 10px;zIndex: 1"
          >
            <a-icon type="left-circle" />
          </div>
          <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
            <a-icon type="right-circle" />
          </div>
          <div v-for="banner in banners" :key="banner._id" >
            <h3>1</h3>
            <a-col :span="12">
            <img :src="banner.img" style="object-fit: contain; width: 500px; "/>
            </a-col>
          </div>
        </a-carousel> -->
        <a-card class="mb-2" :title="$t('yourcredit')" :bordered="false">
          <p style="text-align:left; font-weight: bold;">
            <span style="color: #7E8890;">
              {{$t('credit')}}
            </span>
            <span style="float:right;">
                {{balance}}
            </span>
          </p>
        </a-card>
        <a-card :title="$t('balanceinfo')" :bordered="false" style="color: #7E8890;">
          <p style="text-align:left; font-weight: bold;">
            <span>
              {{$t('balanceday')}}
            </span>
            <span style="float:right;">
                {{realBets_day}}
            </span>
          </p>
          <p style="text-align:left; font-weight: bold;">
            <span>
              {{$t('winloseday')}}
            </span>
            <span style="float:right;">
                {{bets_day}}
            </span>
          </p>
          <p style="text-align:left; font-weight: bold;">
            <span>
              {{$t('balanceyesterday')}}
            </span>
            <span style="float:right;">
                {{realBets_day_before}}
            </span>
          </p>
          <p style="text-align:left; font-weight: bold;">
            <span>
              {{$t('winloseyesterday')}}
            </span>
            <span style="float:right;">
                {{bets_day_before}}
            </span>
          </p>
        </a-card>
      </a-col>
      <a-col class="w-full md:w-1/2  p-2">
        <a-card :title="$t('yourmember')" class="mb-2" :bordered="false" style="color: #7E8890;">
          <div id="textbox">
            <p class="alignleft textbold">{{$t('member')}}</p>
            <p class="aligncenter textbold">{{$t('agent')}}</p>
          </div>
          <div id="textbox">
            <p class="alignleft textbold">{{user}}</p>
            <p class="aligncenter textbold">{{agents}}</p>
          </div>
        </a-card>
        <a-card :title="$t('newmember')" :bordered="false" style="color: #7E8890;">
          <div id="textbox">
            <p class="alignleft textbold">{{$t('day')}}</p>
            <p class="aligncenter textbold">{{$t('week')}}</p>
            <p class="alignright textbold">{{$t('mount')}}</p>
          </div>
          <div id="textbox">
            <p class="alignleft textbold">{{user_day}}</p>
            <p class="aligncenter textbold">{{user_mount}}</p>
            <p class="alignright textbold">{{user_weekly}}</p>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Home',
  data () {
    return {
      agents: 0,
      balance: 0,
      user: 0,
      user_day: 0,
      user_mount: 0,
      user_weekly: 0,
      realBets_day: 0,
      bets_day: 0,
      realBets_day_before: 0,
      bets_day_before: 0,
      banners: []
    }
  },
  created () {
    this.fetchData()
    this.getbanners()
  },
  methods: {
    async fetchData () {
      try {
        const response = await axios.get('/1.0/agent/dashboard')
        if (response.status === 200) {
          this.agents = response.data.agents
          this.balance = response.data.balance
          this.user = response.data.user
          this.user_day = response.data.user_day
          this.user_weekly = response.data.user_weekly
          this.user_mount = response.data.user_mount
          this.realBets_day = response.data.realBets_day ? response.data.realBets_day : 0
          this.bets_day = response.data.bets_day ? response.data.bets_day : 0
          this.realBets_day_before = response.data.realBets_day_before ? response.data.realBets_day_before : 0
          this.bets_day_before = response.data.bets_day_before ? response.data.bets_day_before : 0
        }
      } catch (error) {

      }
    },
    async getbanners () {
      const { data } = await axios.get('/1.0/agent/banners')
      this.banners = data.banners
    }
  }
}
</script>
<style>
  .ant-card {
    border-radius: 10px !important;
  }
  .ant-card-head {
    border-radius: 10px 10px 0px 0px !important;
    /* background-color: red !important; */
  }
</style>
<style scoped>
.textbold {
  font-weight: bold;
}
.ant-carousel >>> .slick-list {
  border-radius: 10px;
}
/* slick-slide slick-active slick-current */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  border-radius: 10px;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
  color: black;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}

#textbox {display:flex; flex-flow:row wrap;}

.alignleft {
  float: left;
  width:33.33333%;
  text-align:center;
}
.aligncenter {
  float: left;
  width:33.33333%;
  text-align:center;
}
.alignright {
 float: left;
 width:33.33333%;
 text-align:center;
}
</style>
