export const formItemLayout = {
  labelCol: {
    sm: { span: 24 },
    md: { span: 7 }
  },
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 12 }
  }
}

export const formTailLayout = {
  labelCol: {
    sm: { span: 24 },
    md: { span: 24 }
  },
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 24 }
  }
}
