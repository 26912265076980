<template>
    <div>
        <a-row type="flex" justify="space-between">
          <a-col :span="12">
            <span class="textbold">{{$t('username')}}:</span><br />
            <a-input-search :placeholder="$t('username')" style="width: 350px" @search="onSearch" @change="searchChange" />
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-between" style="padding-top: 2%">
          <a-col :lg="16" :xs="24" :order="2">
            <span class="textbold">{{$t('date')}}</span><br />
            <a-range-picker
              format="YYYY/MM/DD"
              showTime
              @change="onChangeDate"
              @ok="selectDate"
              :value="[stardate, enddate]"
            />
          </a-col>
          <a-col :lg="4" :xs="24" :order="1">
            <span class="textbold">{{$t('daterange')}}</span> <br />
            <a-select default-value="day" style="width: 100%" @change="changeDateRange">
              <a-select-option value="day">
                {{$t('today')}}
              </a-select-option>
              <a-select-option value="yesterday">
                {{$t('yesterday')}}
              </a-select-option>
              <a-select-option value="lastweek">
                {{$t('lastweek')}}
              </a-select-option>
              <a-select-option value="week">
                {{$t('week')}}
              </a-select-option>
              <a-select-option value="month">
                {{$t('month')}}
              </a-select-option>
              <a-select-option value="lastmonth">
                {{$t('lastmonth')}}
              </a-select-option>
              <a-select-option value="all">
                {{$t('all')}}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <br />
        <a-row type="flex" justify="space-between">
            <a-col :lg="24" :xs="24" :order="1">
            <a-checkbox class="textbold" :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
              {{$t('selectall')}}
            </a-checkbox>
            <a-checkbox-group class="textbold" v-model="checkedList" :options="plainOptions" @change="onChangeProduct" />
            </a-col>
            <a-col :lg="24" :xs="24" :order="2" style="margin-top: 20px">
                <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="middle"
                :loading="loadingData"
                :scroll="{ x: 'calc(700px + 50%)', y: 300 }"
                :rowClassName="(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'"
                >
                <a slot="balance" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ text }}</a></a>
                <a slot="turnover" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ text }}</a></a>
                <!-- <a slot="event" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ text }}</a></a> -->
                <a slot="memberwl" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ text }}</a></a>
                <a slot="agentwl" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ text }}</a></a>
                <a slot="resellerwl" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ text }}</a></a>
                </a-table>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'

const plainOptions = []
const columns = [
  {
    title: 'Username',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
    width: '10%',
    align: 'center'
  },
  {
    title: 'Nickname',
    dataIndex: 'nickname',
    key: 'nickname',
    width: '9%',
    align: 'center'
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    align: 'center',
    scopedSlots: { customRender: 'balance' }
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: '7%',
    align: 'center'
  },
  {
    title: 'Wallet Type',
    dataIndex: 'wallettype',
    key: 'wallettype',
    width: '8%',
    align: 'center'
  },
  {
    title: 'Turnover',
    dataIndex: 'turnover',
    key: 'turnover',
    align: 'center',
    scopedSlots: { customRender: 'turnover' }
  },
  // {
  //   title: 'Event',
  //   dataIndex: 'event',
  //   key: 'event',
  //   align: 'center',
  //   scopedSlots: { customRender: 'event' }
  // },
  {
    title: 'Member',
    align: 'center',
    children: [
      {
        title: 'W/L',
        dataIndex: 'memberwl',
        key: 'memberwl',
        align: 'center',
        scopedSlots: { customRender: 'memberwl' }
      }
    ]
  },
  {
    title: 'Agent',
    align: 'center',
    children: [
      {
        title: 'W/L',
        dataIndex: 'agentwl',
        key: 'agentwl',
        align: 'center',
        scopedSlots: { customRender: 'agentwl' }
      }
    ]
  },
  {
    title: 'Reseller',
    align: 'center',
    children: [
      {
        title: 'W/L',
        dataIndex: 'resellerwl',
        key: 'resellerwl',
        align: 'center',
        scopedSlots: { customRender: 'resellerwl' }
      }
    ]
  }
]

const data = []

export default {
  data () {
    return {
      data,
      columns,
      loadingData: false,
      checkedList: [],
      plainOptions,
      username: '',
      stardate: moment().format('YYYY/MM/DD'),
      enddate: moment().format('YYYY/MM/DD'),
      indeterminate: false,
      checkAll: false
    }
  },
  beforeCreate () {
    data.length = 0
  },
  async created  () {
    this.getProduct()
    this.getData()
    this.stardate = moment(this.$route.query.stardate).format('YYYY/MM/DD')
    this.enddate = moment(this.$route.query.enddate).format('YYYY/MM/DD')
  },
  methods: {
    moment,
    async getProduct () {
      try {
        const response = await this.axios.get('/1.0/agent/products')
        if (response.status === 200) {
          this.plainOptions = response.data.products.map((items) => (items.productId))
        }
      } catch (error) {
      }
    },
    async getData (user) {
      data.length = 0
      this.loadingData = true
      try {
        await axios.get('/1.0/agent/reportall/user', { params: { parent_id: this.$route.query.id, username: user, stardate: this.stardate, enddate: this.enddate, codes: JSON.stringify(this.checkedList) } }).then((res) => {
          res.data.users.map((items, index) => {
            data.push({
              key: index + Math.random(),
              id: items._id,
              name: items.username,
              nickname: items.nickname,
              balance: items.balance,
              currency: items.currency,
              wallettype: items.wallettype,
              turnover: items.turnover,
              // event: items.event_totalwinlose_member,
              agentwl: items.totalwinlose_agent,
              memberwl: items.totalwinlose_member,
              resellerwl: items.totalwinlose_reseller
            })
          })
          this.loadingData = false
        })
      } catch (error) {
        this.loadingData = false
      }
    },
    onSearch (value) {
      if (value === '') {
        this.getData()
      } else {
        this.getData(value)
      }
      this.username = value
    },
    selectDate () {
      if (this.username === '') {
        this.getData()
      } else {
        this.getData(this.username)
      }
    },
    onChangeDate (date, dateString) {
      if (dateString.length > 0) {
        this.stardate = dateString[0]
        this.enddate = dateString[1]
      } else {
        this.stardate = ''
        this.enddate = ''
      }
    },
    changeDateRange (value) {
      switch (value) {
        case 'day':
          this.stardate = moment().format('YYYY/MM/DD')
          this.enddate = moment().format('YYYY/MM/DD')
          break
        case 'yesterday':
          this.stardate = moment().subtract(1, 'days').format('YYYY/MM/DD')
          this.enddate = moment().subtract(1, 'days').format('YYYY/MM/DD')
          break
        case 'lastweek':
          this.stardate = moment().subtract(1, 'weeks').startOf('week').format('YYYY/MM/DD')
          this.enddate = moment().subtract(1, 'weeks').endOf('week').format('YYYY/MM/DD')
          break
        case 'week':
          this.stardate = moment().subtract(0, 'weeks').startOf('week').format('YYYY/MM/DD')
          this.enddate = moment().subtract(0, 'weeks').endOf('week').format('YYYY/MM/DD')
          break
        case 'month':
          this.stardate = moment().subtract(0, 'months').startOf('months').format('YYYY/MM/DD')
          this.enddate = moment().subtract(0, 'months').endOf('months').format('YYYY/MM/DD')
          break
        case 'lastmonth':
          this.stardate = moment().subtract(1, 'months').startOf('months').format('YYYY/MM/DD')
          this.enddate = moment().subtract(1, 'months').endOf('months').format('YYYY/MM/DD')
          break
        case 'all':
          this.stardate = ''
          this.enddate = ''
          break
        default:
          break
      }
      this.getData(this.username)
    },
    searchChange (event) {
      const value = event.target.value
      if (value === '') {
        this.getData()
      }
    },
    onCheckAllChange (e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.plainOptions : [],
        indeterminate: false,
        checkAll: e.target.checked
      })
      this.getData(this.username)
    },
    onChangeProduct () {
      this.getData(this.username)
    }
  }
}
</script>
<style>
  .textbold {
    font-weight: bold;
  }
  .table-row-light {
    background-color: #ffffff;
  }
  .table-row-dark {
      background-color: #fbfbfb;
  }
</style>
